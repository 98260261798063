import { Box, Drawer, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { MHidden } from "src/components/@material-extend";
import Scrollbar from "src/components/Scrollbar";
import NavSectionSettings from "src/components/NavSectionSettings";
import SettingsSidebarConfig from "./SettingsSidebarConfig";
import Logo from "src/components/Logo";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import NavSection from "../../components/NavSection";
import { get_root_value } from "src/utils/domUtils";

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

export default function SettingsSidebar() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const { navbarList, userInfo } = useContentSetting();

  const get_logo_path =
    userInfo?.is_membership_purchase || navbarList?.community
      ? "thesource"
      : "welcome";

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [pathname]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to={get_logo_path}
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo />
        </Box>
      </Box>
      {/* <NavSection navConfig={SettingsSidebarConfig(navbarList, userInfo)} /> */}
      <NavSectionSettings
        navConfig={SettingsSidebarConfig(navbarList, userInfo)}
      />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  return (
    <RootStyle>
      <MHidden width="lgUp">
        <IconButton
          className="menu-icon-settings"
          onClick={() => setOpen(!open)}
        >
          <SettingsIcon
            sx={{ color: get_root_value("--portal - theme - primary") }}
          />
        </IconButton>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              height: "calc(100% - 70px)",
              top: 64,
            },
            className: "sidebar-drawer",
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, height: "calc(100% - 70px)", top: 64 },
            className: "sidebar-drawer",
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
