import { CircularProgress, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  confirm_one_time_payment,
  confirm_subscription_payment,
  get_intent_client_secret_for_one_time,
  get_site_setting,
  pay_now_for_subscription,
  requestDetailApi,
} from "src/DAL/Payment/Payment";
import TransactionList from "../Billing/TransactionList";
import CardInformation from "./CardInformation";
import RequestCard from "./RequestCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStripe } from "@stripe/react-stripe-js";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PendingRequests = (props) => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const stripe = useStripe();
  const [requestData, setRequestData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCard, setIsLoadingCard] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [resPostData, setResPostData] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  //Getting lesson detail
  const getRequestDetail = async () => {
    const result = await requestDetailApi(params.request_slug);
    if (result.code === 200) {
      if (result.payment_request.is_first_paid === true) {
        navigate(`/active-request/${params.request_slug}`);
      }

      setRequestData(result.payment_request_info);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/payment-request`);
    }
  };

  const payNowForSubscription = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
      source_token: token,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await pay_now_for_subscription(postData);

    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar("Payment succeeded successfully.", {
        variant: "success",
      });
      navigate(`/payment-request`);
    }
    if (result.code === 210) {
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
        price_id: result.stripe_initial_price_id,
        recurring_price_id: result.strip_recurring_price_id,
        subscription_id: result.strip_subscription_id,
      };
      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      if (requestData.request_type === "recurring") {
        console.log("i am");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
      setIsLoadingCard(false);
    }
  };

  const getIntentClientSecretForOneTime = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await get_intent_client_secret_for_one_time(postData);

    if (result.code === 200) {
      // confirm payment data
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
      };

      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);

      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const confirmCardPayment = async (postData) => {
    if (requestData.request_type === "recurring") {
      const result = await confirm_subscription_payment(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/payment-request`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    } else {
      const result = await confirm_one_time_payment(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/payment-request`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    }
  };

  const handleSecureCard = (client_secret, cardElement, postData) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        setIsLoadingCard(false);
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }

        confirmCardPayment(postData);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCardAction = (card_token, cardElement) => {
    console.log(card_token, "card_token");
    if (requestData.request_type === "recurring") {
      payNowForSubscription(card_token, cardElement);
    } else {
      getIntentClientSecretForOneTime(card_token, cardElement);
    }
  };

  useEffect(() => {
    getRequestDetail();
  }, [params.request_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <IconButton
        className="back-screen-button"
        onClick={() => navigate("/payment-request")}
      >
        <ArrowBackIcon />
      </IconButton>
      <h2 className="mb-3">Payment Request Information</h2>
      <RequestCard bill={requestData} btn_title="View detail" />
      <div className="col-12"></div>
      <CardInformation
        isLoading={isLoadingCard}
        setIsLoading={setIsLoadingCard}
        handleCardAction={handleCardAction}
      />
    </div>
  );
};

export default PendingRequests;
