import "./fonts/FuturaBT-Book.woff2";
import "./fonts/FuturaStd-Book.woff";
import "./css/style.css";
import WebHeader from "./WebHeader";
import WebFooter from "./WebFooter";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  podcast_detail_by_slug,
  websitePagesContent,
} from "src/DAL/WebPages/WebPages";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  FacebookShareCount,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/CustomConfirmation";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PodcastDetail() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = useState({});
  const [podData, setPodData] = useState(location.state);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const getWebsitePagesContent = async () => {
    const result = await websitePagesContent(
      JSON.stringify({ page_slug: params.page_slug })
    );
    if (result.code === 200) {
      setPageData(result.Sale_page);
      get_podcast_detail();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_podcast_detail = async () => {
    const result = await podcast_detail_by_slug({
      room_slug: params.podcast_slug,
    });
    if (result.code === 200) {
      setPodData(result.room);
      setIsLoading(false);
    } else {
      navigate(`/${params.page_slug}`);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const setCopiedPassword = () => {
    enqueueSnackbar("URL Copied To Clipboard", { variant: "success" });
  };

  useEffect(() => {
    getWebsitePagesContent();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="landing-page">
      <WebHeader data={pageData} setIsLoading={setIsLoading} />
      <section className="web_about_wrapper wb_mb-96 web-margin-after-header">
        <div className="container">
          <div className="row justify-content-center podcast-detail">
            <div className="col-xl-9">
              <div className="underline-div">
                <Link to={`/${params.page_slug}`}>ALL POST</Link>
              </div>
              <div className="wb_money_border_div mt-3 mt-lg-5 banner-black-description podcast_short_description">
                <h1>{htmlDecode(podData.title)}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: podData.short_description,
                  }}
                ></div>
                {podData.podcast_embed_code && (
                  <div className="row mt-3 mt-lg-5 wb_inner_border">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: podData.podcast_embed_code,
                      }}
                    ></div>
                  </div>
                )}
                <div className="my-3 my-lg-5 podcast_detail_description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: podData.detail_description,
                    }}
                  ></div>
                </div>
                <div className="row ms-0 wb_border-tb justify-content-center justify-content-md-start">
                  <div className="col">
                    <ul>
                      <li>
                        <FacebookShareButton url={window.location.href}>
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton url={window.location.href}>
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton url={window.location.href}>
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                      </li>
                      <li>
                        <WhatsappShareButton url={window.location.href}>
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                      </li>
                      <CopyToClipboard
                        text={window.location.href}
                        onCopy={() => setCopiedPassword()}
                      >
                        <li className="link-share-icon">
                          <i className="fa-solid fa-link ms-2 mt-2"></i>
                        </li>
                      </CopyToClipboard>
                    </ul>
                  </div>
                  <div className="col-auto mt-3 mt-md-0">
                    <p className="mt-0">{htmlDecode(podData.podcast_type)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WebFooter data={pageData} setIsLoading={setIsLoading} />
    </div>
  );
}
