// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";

import { Card, Typography, Grid, Box, Avatar, Stack } from "@mui/material";
import Iconify from "src/components/Iconify";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { bgFullImage, profileBg } from "src/assets";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import Slider from "react-slick";
import { get_root_value } from "src/utils/domUtils";

const ImageStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "250px",
  objectFit: "cover",
});

export default function DashboardWebinarCard({
  // title,
  // icon,
  endTime,
  handleClick,
  // description,
  // image,
  // start_date,
  // sx,
  data,
  lockStatement,
  ...other
}) {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(endTime)
  );
  const CustomNextArrow = (props) => (
    <Iconify
      className="custom-next-arrow"
      onClick={props.onClick}
      icon={"ooui:next-ltr"}
      width={18}
      height={18}
    />
  );

  // Custom Previous button component
  const CustomPrevArrow = (props) => (
    <Iconify
      className="custom-prev-arrow"
      onClick={props.onClick}
      icon={"ooui:next-rtl"}
      width={18}
      height={18}
    />
  );
  let set_infinite = true;
  if (data.length < 3) {
    set_infinite = false;
  }
  var settings = {
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    dots: false,
    autoplay: true,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function TextTruncate(text) {
    const words = text.split(" ");
    const isTruncated = words.length > 20;
    const truncatedText = isTruncated
      ? words.slice(0, 20).join(" ") + "..."
      : text;
    return truncatedText;
  }
  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining(endTime);
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining.total <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endTime]);
  function calculateTimeRemaining(endTime) {
    const now = moment();
    const end = moment(endTime, "HH:mm");
    const duration = moment.duration(end.diff(now));

    return {
      total: duration.asSeconds(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }
  const { hours, minutes, seconds } = timeRemaining;
  // console.log(hours, minutes, seconds, "dfkdskf");
  return (
    // <Card
    //   sx={{
    //     boxShadow: 5,
    //     textAlign: "center",
    //     bgcolor: "#000",
    //     // background:
    //     //   "linear-gradient(90deg, rgba(14,75,83,0.8883928571428571) 0%, rgba(2,7,69,0.7959558823529411) 58%)",
    //     height: "auto",
    //     borderRadius: 1,
    //   }}
    //   {...other}>
    //   <div className="d-flex flex-column">
    //     <img
    //       src={s3baseUrl + image}
    //       class="rounded"
    //       width="100%"
    //       height="100%"
    //       alt="Image"
    //     />
    //     <div className="card-text text-start " style={{ padding: "20px 20px" }}>
    //       <div>
    //         <Typography
    //           className=" mb-3"
    //           variant="h5"
    //           sx={{ fontWeight: 600, fontSize: 22, color: "#ffff" }}>
    //           {title}
    //         </Typography>
    //       </div>
    //       <div className="">
    //         <Typography
    //           className="mb-3"
    //           sx={{ fontSize: "14px", opacity: 0.8, color: "#e0e0e0" }}>
    //           {description}
    //         </Typography>
    //       </div>
    //       <div>
    //         <div className="d-flex  align-items-center">
    //           <div className="me-1" style={{ color: "#c7d8f2" }}>
    //             <Iconify
    //               icon={"fluent-mdl2:date-time"}
    //               width={25}
    //               height={25}
    //             />
    //           </div>
    //           <div className="ms-2">
    //             <Typography
    //               variant=""
    //               sx={{ fontSize: "12px", opacity: 1, color: "#ffff" }}>
    //               {start_date}
    //             </Typography>
    //             {/* <Typography
    //               variant="subtitle2"
    //               sx={{ fontSize: "12px", opacity: 1, color: "#ffff" }}>
    //               12:00 PM - 15:12 PM
    //             </Typography> */}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   {/* <Typography
    //     className="text-end card-footer webinar-footer"
    //     variant="subtitle1"
    //     sx={{ opacity: 1, color: "#ffff" }}
    //   >
    //     <div>
    //       {timeRemaining.total > 0 ? (
    //         <div className="d-flex align-items-center justify-content-end">
    //           <Iconify
    //             icon={"game-icons:sands-of-time"}
    //             width={14}
    //             height={14}
    //             className="me-1"
    //             style={{ color: "#c7d8f2" }}
    //           />

    //           <Typography
    //             variant=""
    //             sx={{ fontSize: "14px", opacity: 1, color: "green" }}
    //           >
    //             {String(hours).padStart(2, "0")}:
    //             {String(minutes).padStart(2, "0")}:
    //             {String(seconds).padStart(2, "0")}
    //           </Typography>
    //         </div>
    //       ) : (
    //         <h3 className="">Time's up!</h3>
    //       )}
    //     </div>
    //   </Typography> */}
    // </Card>

    <Slider {...settings}>
      {data.map((item, index) => {
        return (
          <div key={index}>
            <Card
              className="mui-custom-card secondary-background "
              sx={{
                cursor: "pointer",

                color: get_root_value("--text-primary-color"),
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {item.card_button && item.card_button.button_title && (
                  <button
                    variant="contained"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    className={
                      lockStatement
                        ? `small-contained-button mui-card-button d-flex centerAlign ${
                            index == 0 ? "centerAlign0" : ""
                          }`
                        : "small-contained-button mui-card-button mui-card-button-program d-flex"
                    }
                    onClick={
                      item.card_button.handleClick
                        ? () => {
                            item.card_button.handleClick(item);
                          }
                        : undefined
                    }
                  >
                    {item.card_button.button_title}
                  </button>
                )}
                <ImageStyle
                  alt={item.title}
                  src={item.card_image}
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                />
              </Box>

              <Stack
                spacing={2}
                className="mui-custom-card-content "
                sx={{
                  height: "160px",
                }}
                onClick={
                  handleClick
                    ? () => {
                        handleClick(item);
                      }
                    : undefined
                }
              >
                <h3>{htmlDecode(item.card_title)}</h3>
                {item.card_short_description && (
                  <p className="mui-custom-card-description">
                    {htmlDecode(TextTruncate(item.card_short_description))}
                  </p>
                )}
                {item.card_short_description_2 && (
                  <p className="card_lower_title_description">
                    {htmlDecode(TextTruncate(item.card_short_description_2))}
                  </p>
                )}
                {item.card_gray_description && (
                  <p className="mui_gray_description">
                    <span>{TextTruncate(item.card_gray_description)}</span>
                  </p>
                )}
              </Stack>
            </Card>
          </div>
        );
      })}
    </Slider>
  );
}
