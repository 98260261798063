import "./fonts/FuturaBT-Book.woff2";
import "./fonts/FuturaStd-Book.woff";
import "./css/style.css";
import paymentImage from "./images/payment.jpg";
import { websitePagesContent } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { useParams } from "react-router-dom";
import WebHeader from "./WebHeader";
import WebFooter from "./WebFooter";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ThanksPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const params = useParams();
  const getWebsitePagesContent = async () => {
    const result = await websitePagesContent(
      JSON.stringify({ page_slug: params.page_slug })
    );

    if (result.code === 200) {
      setPageData(result.Sale_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWebsitePagesContent();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div
      className="landing-page thank-you-page"
      style={{
        background: `url(${paymentImage}) no-repeat center center`,
        backgroundSize: "cover",
      }}
    >
      <WebHeader data={pageData} setIsLoading={setIsLoading} />
      <section className="web_ready-section web-margin-after-header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlDecode(pageData.thankyou_page_content),
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <WebFooter data={pageData} setIsLoading={setIsLoading} />
    </div>
  );
}
