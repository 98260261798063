import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddEarningsDrawer from "../../pages/90DayPlan/AddEarning";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#1d1c1d",
  //   bgcolor: "#fff",
  //   borderRadius: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 2,
  color: "white",
};
const useStyles = makeStyles(() => ({
  menuPaper: {
    backgroundColor: "#1d1c1d",
    color: "white",
  },
}));
const ITEM_HEIGHT = 48;

export default function MenuOption({ id }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ConfirmDelete = () => {
    console.log(id);
  };
  //   drawer states
  const handleOpenDrawer = (id) => {
    setAnchorEl(null);
    setOpenDrawer(true);
  };
  const hanldeClose = () => {
    setOpenDrawer(!open);
  };
  //   drawer states end here

  return (
    <div>
      <AddEarningsDrawer
        open={openDrawer}
        setOpenDrawer={setOpenDrawer}
        hanldeClose={hanldeClose}
        handleOpen={handleOpenDrawer}
      />
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
        // PaperProps={{
        //   style: {
        //     maxHeight: ITEM_HEIGHT * 4.5,
        //     width: "10ch",
        //   },
        // }}
      >
        <MenuItem onClick={() => handleOpenDrawer(id)}>Edit</MenuItem>
        <MenuItem onClick={handleOpenModal}>Delete</MenuItem>
      </Menu>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Delete
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete.
            </Typography>
            <div className="text-end">
              <button
                className="small-contained-button me-2"
                onClick={ConfirmDelete}
              >
                Agree
              </button>
              <button
                className="small-contained-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
