import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  TIME_ZONE_CONVERSION,
  get_date_with_user_time_zone,
} from "src/utils/constants";

export default function EventDetailNew({ eventDetailData }) {
  const { userInfo, adminTimeZone } = useContentSetting();

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  return (
    <>
      <div className="event-details">
        <h1>{htmlDecode(eventDetailData.event_title)}</h1>
        <div className="row">
          <div className="col-12 mt-1">
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex me-4 event-description-title">
                <span>From : </span>
                <p className="ms-1 date-color">
                  {get_start_end_time(eventDetailData.start_date_time)}
                </p>
              </div>
              <div className="d-flex event-description-title">
                <span>To : </span>
                <p className="ms-1 date-color">
                  {get_start_end_time(eventDetailData.end_date_time)}
                </p>
              </div>
            </div>
            {eventDetailData.description && (
              <>
                <div className="col-12 mb-3 event-description-title">
                  <b>Description :</b>&nbsp;
                </div>
                <div className="col-12 event-description-details">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetailData.description,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
