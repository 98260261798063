// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";

import { Card, Typography, Grid, Box } from "@mui/material";
import Iconify from "src/components/Iconify";
import { bgFullImage, itemFeatureLayer_1 } from "src/assets";
import { s3baseUrl } from "src/config/config";
// components
// import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "15%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

// ----------------------------------------------------------------------

// ProgramAccess.propTypes = {
//   color: PropTypes.string,
//   icon: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   count: PropTypes.number.isRequired,
//   sx: PropTypes.object,
// };

export default function ProgramAccess({
  title,
  count,
  short_description,
  total_lesson_duration,
  no_of_lesson,
  icon,
  ...other
}) {
  return (
    <div className="pragram-acc" style={{ width: "100%" }}>
      {/* <img
        src={bgFullImage}
        class="rounded"
        width="100%"
        height="300px"
        alt="Image"
      /> */}
      <Avatar
        src={s3baseUrl + icon}
        sx={{ height: 300, width: "100%" }}
        variant="rounded"></Avatar>
      <div
        className="pragram-acc-content rounded-bottom"
        style={{ padding: "10px" }}>
        <Typography
          className=" mb-3"
          variant="h5"
          sx={{ fontWeight: 600, fontSize: 16, color: "#ffff" }}>
          {title}
        </Typography>
        <p class="programme-card-desc cut-text short-text">
          {short_description}
        </p>
        {/* <p className="programme-card-desc">{short_description}</p> */}
        <div className="card-button">
          <p className="programme-duration">
            <span>
              {no_of_lesson} Lessons &nbsp; | &nbsp; {total_lesson_duration}
            </span>
          </p>
        </div>
      </div>
    </div>

    // <Card
    //   sx={{
    //     boxShadow: 5,
    //     textAlign: "center",
    //     bgcolor: color,

    //     height: 100,
    //     borderRadius: 1,
    //     ...sx,
    //   }}
    //   {...other}
    // >
    //   <Grid container rowSpacing={1}>
    //     <Grid item xs={3}>
    //       <IconWrapperStyle
    //         sx={{
    //           my: 3.5,
    //           width: 45,
    //           height: 45,

    //           bgcolor: "#fff",
    //         }}
    //       >
    //         <Iconify
    //           icon={icon}
    //           width={25}
    //           height={25}
    //           sx={{ color: { color } }}
    //         />
    //       </IconWrapperStyle>
    //     </Grid>
    //     <Grid item xs={9} sx={{ my: 2, color: "#fff" }}>
    //       <Typography variant="h6" sx={{ fontWeight: "bold" }}>
    //         {Math.round(count * 10) / 10}
    //       </Typography>
    //       <Typography variant="subtitle1" sx={{ opacity: 1, width: "98%" }}>
    //         {title}
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </Card>
  );
}
