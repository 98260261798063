import { invokeApi } from "../../../bl_libs/invokeApi";

export const categoriesListing = async (data) => {
  const requestObj = {
    path: `api/category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddCategories = async (data) => {
  const requestObj = {
    path: `api/category/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditCategories = async (category_slug, data) => {
  const requestObj = {
    path: `api/category/${category_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteCategory = async (category_slug) => {
  const requestObj = {
    path: `api/category/${category_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
