import React from "react";
import no_access from "../../../assets/images/no_access.png";
export default function NoAccess(props) {
  return (
    <div className="no-access-string">
      <img className="mx-auto" src={no_access} />
      <p className="mt-3">{`You have no access to see the ${props.title}`}</p>
    </div>
  );
}
