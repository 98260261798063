import React from "react";

export const Payment = () => {
  return (
    <div className="container">
      <div className="card-payment">
        <button className="proceed">
          <svg className="sendicon" width="24" height="24" viewBox="0 0 24 24">
            <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"></path>
          </svg>
        </button>
        <img
          src="https://seeklogo.com/images/V/VISA-logo-62D5B26FE1-seeklogo.com.png"
          className="logo-card"
        />
        <label className="label-card">Card number:</label>
        <input
          className="payment-input"
          id="user"
          type="text"
          className="input cardnumber"
          placeholder="1234 5678 9101 1121"
        />
        <label>Name:</label>
        <input className="input name" placeholder="Edgar Pérez" />
        <label className="toleft">CCV:</label>
        <input className="input toleft ccv" placeholder="321" />
      </div>
      <div className="receipt">
        <div className="col mt-4">
          {/* cost class here in the bottom class */}
          <h3 className="">Cost</h3>
          <p>$400</p>
          <br />
          <h3 className="">Seller Name:</h3>
          <p>Kim Calvert</p>
        </div>
        <div className="col mt-4">
          <h3 className="">Bought Items:</h3>
          <p> Thinking Into Result</p>
          <p className="bought-items description">Lesson 1, lesson 2</p>
          <p className="bought-items price">$200 (50% discount)</p>
          <br />
          <h3 className="bought-items">Ignite</h3>
          <p className="bought-items description">
            Look Father, it has Ignition!
          </p>
          <p className="bought-items price">$200 (50% discount)</p>
          <br />
        </div>

        <p className="comprobe">
          This information will be sended to your email
        </p>
      </div>
    </div>
  );
};
