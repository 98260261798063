import { invokeApi } from "src/bl_libs/invokeApi";

export const all_transaction_list = async () => {
  const requestObj = {
    path: `api/member/all_transaction`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
