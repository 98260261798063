import { invokeApi } from "../../bl_libs/invokeApi";

export const videos_listing = async (data) => {
  const requestObj = {
    path: `api/helping_videos/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const admin_videos_detail = async (data) => {
  const requestObj = {
    path: `api/helping_videos/detail_client/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteAudioApi = async (data, slug) => {
  const requestObj = {
    path: `/api/program/${slug}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
