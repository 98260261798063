import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SettingsSidebar from "./dashboard/SettingsSidebar";
import { useState } from "react";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 15;

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function SettingsLayout() {
  const location = useLocation();

  function test(words) {
    var n = words.split("=");
    return n[n.length - 1];
  }
  let mobileView = test(location.search);

  return (
    <RootStyle className="settings-screen-small">
      {mobileView == "mobile" ? (
        ""
      ) : (
        <>
          <SettingsSidebar />
        </>
      )}

      <MainStyle className="settings-small-component">
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
