import React, { useState } from "react";
import { Card, Tooltip, CircularProgress } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { s3baseUrl } from "src/config/config";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
function ResourcesCard({ imageLink, resource }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async (path) => {
    setIsLoading(true);
    const file_path = `${s3baseUrl}${path}`;
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getResourceImage = () => {
    if (resource.document_thumbnail) {
      return s3baseUrl + resource.document_thumbnail;
    } else if (resource.document_type === "image") {
      return s3baseUrl + resource.document_images_url.thumbnail_2;
    } else {
      const ext = resource.document_file_url.split(".").pop();
      if (imageLink[ext]) {
        return imageLink[ext];
      } else {
        return imageLink.other;
      }
    }
  };

  return (
    <>
      <Card className="lesson-card w-100">
        <div className="row p-3">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <img
              className="lesson-card-image"
              src={getResourceImage()}
              alt="name"
            />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <h4 className="lesson-heading">{htmlDecode(resource.title)} </h4>
            <div
              className="normal-font"
              dangerouslySetInnerHTML={{
                __html: resource.detailed_description,
              }}
            ></div>
          </div>
          <div className="col-12 download-icon">
            {isLoading ? (
              <CircularProgress className="icon-style" />
            ) : (
              <Tooltip title="Download">
                <DownloadIcon
                  onClick={() => {
                    let path = "";
                    if (resource.document_type === "image") {
                      path = resource.document_images_url.thumbnail_1;
                    } else {
                      path = resource.document_file_url;
                    }
                    handleDownload(path);
                  }}
                  className="icon-style"
                />
              </Tooltip>
            )}
          </div>
        </div>
      </Card>
    </>
  );
}

export default ResourcesCard;
