// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";

import { Card, Typography, Grid, Box } from "@mui/material";
import Iconify from "./Iconify";
// components
// import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "15%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

// ----------------------------------------------------------------------

// SummaryCard.propTypes = {
//   color: PropTypes.string,
//   icon: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   count: PropTypes.number.isRequired,
//   sx: PropTypes.object,
// };

export default function SummaryCard({
  title,
  count,
  icon,
  color = "#000",
  sx,
  ...other
}) {
  console.log;
  return (
    <Card
      sx={{
        boxShadow: 5,
        textAlign: "center",
        bgcolor: color,

        height: 100,
        borderRadius: 1,
        ...sx,
      }}
      {...other}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={3}>
          <IconWrapperStyle
            sx={{
              my: 3.5,
              width: 45,
              height: 45,

              bgcolor: "#fff",
            }}
          >
            <Iconify
              icon={icon}
              width={25}
              height={25}
              sx={{ color: { color } }}
            />
          </IconWrapperStyle>
        </Grid>
        <Grid item xs={9} sx={{ my: 2, color: "#fff" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {Math.round(count * 10) / 10}
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 1, width: "98%" }}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
