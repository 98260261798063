// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";
import DashboardCircle from "src/components/_dashboard/app/DashboardCircle";
import EventCarousel from "src/components/_dashboard/app/EventCarousel";
import ReviewCardSlider from "src/components/_dashboard/app/ReviewCards";
import ShowEvenetSlider from "src/components/_dashboard/app/EventSlider";
import { useContext, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { project_name } from "src/config/config";
import DashboardCard from "src/components/_dashboard/app/DashboardCard";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import Disclaimer from "src/components/Disclaimer";
import { useEffect } from "react";
import { ProjectInfoApi, memberDetail } from "src/DAL/Profile/Profile";
import ReactVideoPlayerDashboard from "src/components/ReactPlayers/ReactVideoPlayerDashboard";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const {
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    dashboardEventData,
  } = useContentSetting();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [disclaimerDetail, setDisclaimerDetail] = useState("");
  const [disclaimerHeading, setDisclaimerHeading] = useState("");
  const [disclaimerLabel, setDisclaimerLabel] = useState("");
  const [disclaimerButton, setDisclaimerButton] = useState("");
  const [disclaimerStatus, setDisclaimerStatus] = useState("");
  const [memberData, setMemberData] = useState({});

  const is_seen_video = _get_is_seen_video_localStorage();
  // console.log(
  //   dashboardEventData.filter((data) => data.created_for == "general"),
  //   "dashboardEventData"
  // );
  // console.log(dashboardSettingData, "ok");
  //Getting Project Info from API
  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      setDisclaimerDetail(result.member_setting.disclaimer_text);
      setDisclaimerStatus(result.member_setting.is_disclaimer_show);
      setDisclaimerButton(result.member_setting.disclaimer_button_text);
      setDisclaimerLabel(result.member_setting.checkbox_label);
      setDisclaimerHeading(result.member_setting.disclaimer_heading);
      if (result.member_setting.is_disclaimer_show) {
        setopenPasswordModal(true);
      }
      getMemberData();
    }
  };
  const getMemberData = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      console.log(result, "resultresultresultresult");

      setMemberData(result.member);
    }
  };
  useEffect(() => {
    getProjectInfo();
  }, []);
  return (
    // <Page title="Dashboard ">
    <div className="container">
      <div className="row">
        <div className="col-12 dashboard_description">
          {contentSettingData && contentSettingData.dashboard_description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData.dashboard_description,
              }}></div>
          ) : (
            <h2 className="dashboard-heading">
              {dashboardSettingData?.wellcome_text}
            </h2>
          )}
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12 mt-5 mx-auto text-center">
          <DashboardCard data={dashboardSettingData} />
        </div> */}
        {(project_name == "DD" ||
          project_name == "BASIC_DEV" ||
          project_name == "PGI_DEV") && (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <DashboardCircle data={dashboardSettingData} />
            </div>
          </>
        )}
        {
          dashboardSettingData?.video_url && (
            <span>
              {
                is_seen_video === "No" && (
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <ReactVideoPlayerDashboard
                      url={dashboardSettingData?.video_url}
                      setting={dashboardSettingData}
                    />
                  </div>
                )
                // : (
                //   <div
                //     dangerouslySetInnerHTML={{
                //       __html: dashboardSettingData?.welcome_image,
                //     }}></div>
                // )
              }
            </span>
          )
          // : (
          //   <div
          //     dangerouslySetInnerHTML={{
          //       __html: dashboardSettingData?.welcome_image,
          //     }}></div>
          // )
        }
        <div
          dangerouslySetInnerHTML={{
            __html: dashboardSettingData?.welcome_image,
          }}></div>
      </div>
      {memberData.disclaimer_status == false && (
        <Disclaimer
          openPasswordModal={openPasswordModal}
          setopenPasswordModal={setopenPasswordModal}
          disclaimerDetail={disclaimerDetail}
          disclaimerStatus={disclaimerStatus}
          disclaimerButton={disclaimerButton}
          disclaimerHeading={disclaimerHeading}
          disclaimerLabel={disclaimerLabel}
        />
      )}
    </div>
    // </Page>
  );
}
