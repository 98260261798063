import React from "react";
import { useNavigate } from "react-router";
import RequestCard from "../PaymentRequests/RequestCard";

const billing = [
  {
    id: 1,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    month: "10",
    request_type: "onetime",
    currency: "usd",
  },
  {
    id: 2,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    request_type: "recurring",
    currency: "usd",
    month: "5",
  },
  {
    id: 3,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    request_type: "recurring",
    currency: "usd",
    month: "6",
  },
];

const BillingCard = () => {
  const navigate = useNavigate();

  const handleBillingDetail = (bill_Id) => {
    navigate(`/billing/billing-detail/${bill_Id}`);
  };

  return (
    <div className="container">
      <h2 className="mt-5 mb-3">Billing</h2>
      <div className="col-lg-12">
        {billing.map((bill, index) => {
          return <RequestCard bill={bill} btn_title="View detail" />;
        })}
      </div>
    </div>
  );
};

export default BillingCard;
