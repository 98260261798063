import * as React from "react";

import Typography from "@mui/material/Typography";

import { Avatar, Box, Button } from "@mui/material";
import {
  bgFullImage,
  itemFeatureLayer_1,
  itemFeatureLayer_2,
  itemFeatureLayer_3,
  itemFeatureLayer_4,
  profileBg,
} from "src/assets";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import { ReactVideoPlayer } from "src/components";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

let DataArray = [
  {
    image: profileBg,
    title: "Daily dose of imotions",
    description: "",
    program: 8,
    lesson: 4,
  },
  {
    image: bgFullImage,
    title: "An important Life Lesson on Letting Go off Anger",
    description: "",
    program: 1,
    lesson: 3,
  },
  {
    image: itemFeatureLayer_1,
    title: "Best Motivational Words- Muniba Mazari ",
    description: "",
    program: 7,
    lesson: 1,
  },
  {
    image: itemFeatureLayer_2,
    title: "1 Powerful Shakti Success",
    description: "",
    program: 5,
    lesson: 4,
  },

  {
    image: itemFeatureLayer_3,
    title: "Hard work motivation 🔥 Best Study Motivational",
    description: "",
    program: 1,
    lesson: 1,
  },
  {
    image: itemFeatureLayer_4,
    title: "Attitude plays A Very Important Role in Life",
    description: "",
    program: 2,
    lesson: 3,
  },

  ,
];
function formatTime(second) {
  // console.log(seconds, "dsfjls");
  let seconds = parseInt(second);
  if (isNaN(seconds) || seconds < 0) {
    return "00:00";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export default function ProgramActivity({ recentData }) {
  function TextTruncate(text) {
    const words = text.split(" ");
    const isTruncated = words.length > 19;
    const truncatedText = isTruncated
      ? words.slice(0, 19).join(" ") + "..."
      : text;
    return truncatedText;
  }
  return (
    <div>
      <div class="card ">
        <div class="" style={{ height: "350px" }}>
          <Scrollbar
            sx={{
              height: "100%",
              "& .simplebar-content": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
              },
            }}>
            <div class="card-body no-scroll">
              {recentData.map((item, index) => {
                return (
                  <div
                    class="card mb-2 "
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                    }}>
                    <div class="row no-gutters">
                      <div class="col-lg-5">
                        <div className="h-100 w-100 thumbnail">
                          {item.video_url != "" ? (
                            <>
                              <ReactVideoPlayer
                                url={item?.video_url}
                                video_duration={item?.video_duration}
                              />
                            </>
                          ) : (
                            <>
                              {/* <img
                            src={item.image}
                            class="rounded-left"
                            width="100%"
                            height="120px%"
                            alt="Image"
                          /> */}
                              {/* <div className="thumbnail-progress">
                            <div className="text-end me-1">
                              <span>3:00</span>
                            </div>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                          </div> */}
                              <div className="icon-player">
                                <Iconify
                                  className="thumbnail-play-icon"
                                  icon={"el:play-alt"}
                                  width={30}
                                  height={120}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div class="col-lg-7 d-flex ">
                        <div class=" mt-1">
                          <Typography
                            className="mb-1 title"
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: "black",
                            }}>
                            {item?.info?.title}
                          </Typography>
                          <Typography
                            className="mb-1 mt-1"
                            sx={{
                              fontSize: 12,
                              color: "black",
                              opacity: 0.7,
                            }}>
                            {TextTruncate(item?.info?.short_description)}
                          </Typography>
                          {/* <Typography
                            className="mb-1 mt-1"
                            sx={{
                              fontSize: 12,
                              color: "#ffff",
                              opacity: 0.7,
                            }}
                          >
                            
                          </Typography> */}
                          {/* <span style={{ display: "flex", marginTop: "15px" }}>
                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: 10,
                                padding: "3px",
                                marginRight: "3px",
                              }}
                            >
                              {item?.section}
                            </Button>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
}
