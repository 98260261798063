// import CanvasJSReact from "./canvasjs.stock.react";

import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { render } from "react-dom";
import { get_root_value } from "src/utils/domUtils";
import CanvasJSReact from "../../../src/canvasjs.react";
import { EarningListApi } from "../../DAL/90Day/NinetyDay";
import moment from "moment";

var Component = React.Component;
// var CanvasJSReact = require("./canvasjs.react");
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var updateInterval = 500;

var xVal = 0;
var yVal = 10;
var updateInterval = 1000;
var dataLength = 20; // number of dataPoints visible at any point
var updateInterval = 1000;
const Chart = ({ ChartDataList }) => {
  const options = {
    animationEnabled: true,
    backgroundColor: get_root_value("--sidebars-background-color"),
    // exportEnabled: true,
    theme: "dark1", // "light1", "dark1", "dark2"

    axisX: {
      // title: "Days",
      prefix: "",
      interval: 1,
    },

    data: [
      {
        color: get_root_value("--portal-theme-primary"),
        type: "line",
        toolTipContent: "Day {x}: {y}",
        dataPoints: ChartDataList,
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart
        style={{ color: "yellow" }}
        options={options}
        /* onRef = {ref => this.chart = ref} */
      />
    </div>
  );
};
export default Chart;
