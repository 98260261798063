import { Box, List } from "@mui/material";
import NavSectionSettingItem from "./NavSectionSettingItem";

export default function NavSectionSettings({ navConfig }) {
  return (
    <Box>
      <List disablePadding>
        {navConfig.map((item, i) => {
          return <NavSectionSettingItem data={item} key={i} />;
        })}
      </List>
    </Box>
  );
}
