import { user, coin, card, unlock, copy, settings } from "src/assets";
// ----------------------------------------------------------------------
import { Icon } from "@iconify/react";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";

import homeFill from "@iconify/icons-eva/home-fill";
import unlockFill from "@iconify/icons-eva/unlock-fill";
import personFill from "@iconify/icons-eva/person-fill";
import creditCardFilled from "@iconify/icons-eva/credit-card-fill";
import dollarCircleFilled from "@iconify/icons-ant-design/dollar-circle-filled";
const getIcon = (name) => (
  // <img className="navbar-icon" src={name} width={18} height={18} />
  <Icon icon={name} width={18} height={18} />
);

const SettingsSidebarConfig = () => {
  let sidebarMenus = [];
  sidebarMenus.push({
    title: "Payment Request",
    path: "/payment-request",
    icon: getIcon(dollarCircleFilled),
    matches: ["/payment-request", "/pending-request", "/active-request"],
  });
  sidebarMenus.push({
    title: "Transactions",
    path: "/transaction",
    icon: getIcon(dollarCircleFilled),
  });
  sidebarMenus.push({
    title: "Manage Card",
    path: "/manage-card",
    icon: getIcon(creditCardFilled),
  });
  sidebarMenus.push({
    title: "Change Password",
    path: "/change-password",
    icon: getIcon(unlockFill),
    password: true,
  });
  // sidebarMenus.push({
  //   title: "Notification Settings",
  //   path: "/manage-notification",
  //   // icon: getIcon(settings),
  // });
  // sidebarMenus.push({
  //   title: "QR Scanner",
  //   path: "/qr-scanner",
  //   // icon: getIcon(settings),
  // });
  sidebarMenus.push({
    title: "Subscriptions",
    path: "/manage-subscription",
    icon: getIcon(settings2Fill),
  });

  return sidebarMenus;
};
export default SettingsSidebarConfig;
