import "./fonts/FuturaBT-Book.woff2";
import "./fonts/FuturaStd-Book.woff";
import "./css/style.css";
import WebHeader from "./WebHeader";
import WebProgram from "./WebProgram";
import WebTestimonial from "./WebTestimonial";
import WebFooter from "./WebFooter";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import ItemFeaturedCard from "./ItemFeaturedCard";
import { get_success_products } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

function HomePage({ pageData, testimonials, setIsLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [featureSuccessProducts, setFeatureSuccessProducts] = useState({});
  const [unFeatureSuccessProducts, setUnFeatureSuccessProducts] = useState([]);
  const [isLoadingSection, setIsLoadingSection] = useState(true);

  const getSuccessProducts = async () => {
    const result = await get_success_products();
    if (result.code === 200) {
      setFeatureSuccessProducts(result.feature_success_product[0]);
      setUnFeatureSuccessProducts(result.unfeature_success_product);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
    }
  };

  useEffect(() => {
    getSuccessProducts();
  }, []);

  return (
    <div className="landing-page">
      <WebHeader data={pageData} setIsLoading={setIsLoading} />
      <section
        className="web_hero-section web-margin-after-header"
        style={{
          background: `url(${
            s3baseUrl + pageData.sale_page_detail.banner_image
          }) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 banner-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.banner_text,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="web_ready-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.top_text_after_banner,
                }}
              ></div>

              <div className="web_stop-btn">
                <a
                  href={
                    pageData.sale_page_detail
                      .top_section_after_banner_button_link
                  }
                >
                  <button>
                    {htmlDecode(
                      pageData.sale_page_detail
                        .top_section_after_banner_button_text
                    )}
                  </button>
                </a>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </section>
      <WebProgram data={pageData} />
      <section className="web_founder-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row align-items-center">
                <div className="col-md-6 px-50 meet-section pe-md-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.sale_page_detail.introduction_text,
                    }}
                  ></div>

                  <div className="web_read-btn">
                    <a
                      href={pageData.sale_page_detail.introduction_button_link}
                    >
                      <button>
                        {htmlDecode(
                          pageData.sale_page_detail.introduction_button_text
                        )}
                      </button>{" "}
                    </a>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <img
                    src={
                      s3baseUrl + pageData.sale_page_detail.introduction_image
                    }
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="web_life-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 web_life-wrapper-text">
              <div
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.client_section_top_text,
                }}
              ></div>
              <div className="row align-items-center mt-60">
                <div className="col-md-5 col-lg-4 text-center">
                  <img
                    src={
                      s3baseUrl + pageData.sale_page_detail.client_section_image
                    }
                    alt=""
                    className="img-fluid mx-auto"
                  />
                </div>
                <div className="col-md-7 col-lg-8 ps-lg-3 mt-4 mt-md-0">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.sale_page_detail.client_section_heading,
                    }}
                  ></div>
                  <div className="web_border-div " />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.sale_page_detail.client_section_text,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {pageData.sale_page_detail.success_shop_section_top_image && (
        <section className="">
          <img
            src={
              s3baseUrl +
              pageData.sale_page_detail.success_shop_section_top_image
            }
            width="100%"
          ></img>
        </section>
      )}
      <section className="item_feature-wrapper-cards">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div
                className="text-center success_shop_section_text"
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.success_shop_section_text,
                }}
              ></div>
            </div>
          </div>
        </div>
        {isLoadingSection ? (
          <CircularProgress className={classes.loading} color="primary" />
        ) : (
          <ItemFeaturedCard
            unFeatureSuccessProducts={unFeatureSuccessProducts}
          />
        )}
      </section>

      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        featureSuccessProducts && (
          <section className="item_feature-wrapper">
            <div className="">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 feature-item-text ">
                  <h2 className="text-center">
                    {htmlDecode(featureSuccessProducts.title)}
                  </h2>
                  <div
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: featureSuccessProducts.description,
                    }}
                  ></div>
                  {featureSuccessProducts.button_text !== "" && (
                    <div className="feature_read-btn text-center">
                      <a
                        href={featureSuccessProducts.button_link}
                        target="_blank"
                      >
                        <button>
                          {htmlDecode(featureSuccessProducts.button_text)}
                        </button>
                      </a>
                    </div>
                  )}
                </div>
                {featureSuccessProducts.client_image && (
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <img
                      src={
                        s3baseUrl +
                        featureSuccessProducts.client_image.thumbnail_1
                      }
                      width="100%"
                      className="img-fluid"
                    ></img>
                  </div>
                )}
              </div>
            </div>
          </section>
        )
      )}
      <section className="web_stuck-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row feeling-stuck">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.sale_page_detail.book_call_section_text,
                  }}
                ></div>
                <div className="web_read-btn">
                  <a href={pageData.sale_page_detail.book_call_button_link}>
                    <button>
                      {htmlDecode(
                        pageData.sale_page_detail.book_call_button_text
                      )}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {testimonials && <WebTestimonial data={testimonials} />}
      {/* <WebImageSlider data={pageData} /> */}
      <WebFooter data={pageData} setIsLoading={setIsLoading} />
    </div>
  );
}
export default HomePage;
