import { Link as RouterLink, Outlet, Navigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import { member_init_without_token } from "src/DAL/ContentSetting/ContentSetting";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const { handleStripeKey } = useContentSetting();

  const handleGetContentData = async () => {
    const result = await member_init_without_token();
    if (result.code === 200) {
      console.log(result, "fkjsdkfada");
      localStorage.setItem(
        `page_content`,
        JSON.stringify(result?.content_setting?.client_content_setting)
      );
      localStorage.setItem(
        `project_info`,
        JSON.stringify(result?.content_setting?.client_general_setting)
      );

      if (result.site_setting.stripe_mode == "sandBox") {
        handleStripeKey(result.site_setting.sandBox_publish_key);
      } else {
        handleStripeKey(result.site_setting.live_publish_key);
      }
      ReactGA.initialize(
        result?.content_setting?.client_general_setting?.analytic_google_code,
        { debug: true }
      );

      ReactGA.pageview(location.pathname + location.search);
      // setIsLoadingApp(false);
    } else {
      // setIsLoadingApp(false);
    }
  };
  useEffect(() => {
    // console.log("dsjfhkjhdskfjasd");
    handleGetContentData();
  }, []);
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  return (
    <>
      <HeaderStyle>
        {/* <RouterLink to="/">
          <Logo />
          
        </RouterLink> */}
        <div className="row">
          <div className="col-lg-2 col-sm-11">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </div>
          <div className="col-lg-10 col-sm-1">{/* <Logo /> */}</div>
        </div>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
