import React, { useState } from "react";
import { TextField } from "@mui/material";
import { AddGratitudeApi } from "../../DAL/Gratitude/Gratitude";
import { useSnackbar } from "notistack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function AddGratitude({ onCloseDrawer, dataList }) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = React.useState("");
  const [state, setState] = useState({
    gratitudeDescription: "",
    gratitudeImage: "",
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  //Adding Category

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (image === "") {
      enqueueSnackbar("Image is required", { variant: "error" });
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append("gratitude_text", state.gratitudeDescription);
      formData.append("image", image);
      const result = await AddGratitudeApi(formData);
      if (result.code === 200) {
        onCloseDrawer();
        dataList();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <TextField
            className="mt-4 inputs-fields"
            id="outlined-multiline-static"
            label="Gratitude Description"
            multiline
            rows={5}
            name="gratitudeDescription"
            value={state.gratitudeDescription}
            variant="outlined"
            style={{ width: "100%" }}
            required={true}
            onChange={(e) => handleChange(e)}
          />
          <p className="mt-4 mb-0">Recommended Size (1000x670)</p>
          <div className="col-md-12 mt-2 d-flex">
            {previews && (
              <span className="preview mt-2">
                <span onClick={handleRemove}>x</span>
                <img className="image-border" src={previews} />
              </span>
            )}
            {previews == "" && (
              <span className="upload-button mt-2">
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  name="affirmationImage"
                  onChange={handleUpload}
                />
                <label htmlFor="icon-button-file">
                  <CloudUploadIcon />
                </label>
              </span>
            )}
          </div>
          <div className="text-end mt-3">
            <button className="submit-button" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
