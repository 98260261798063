import "./fonts/FuturaBT-Book.woff2";
import "./fonts/FuturaStd-Book.woff";
import "./css/style.css";
import WebHeader from "./WebHeader";
import WebFooter from "./WebFooter";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  send_email_by_page_slug,
  update_contact_image_on_s3,
  website_podcasts_list,
} from "src/DAL/WebPages/WebPages";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useNavigate } from "react-router-dom";
import { ReactVideoPlayer } from "src/components";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

export default function WebPodcasts({ pageData, setIsLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [websitePodcasts, setWebsitePodcasts] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const classes = useStyles();
  const [loadMoreLink, setLoadMoreLink] = useState(
    "api/website_pods/active_website_pods?page=0&limit=15"
  );
  const [inputState, setInputsState] = useState({
    name: "",
    email: "",
    promoteEpisode: "",
    IGHandle: "",
    FBHandle: "",
    linkedinHandle: "",
    youtubeURL: "",
    pinterestURL: "",
    companyName: "",
    companyWebsite: "",
    sizeofEmail: "",
    shortBio: "",
    aboutPodcastEpisode: "",
    danielleCollaborate: "",
    uploadHeadshot: "",
    uploadLogo: "",
  });

  const get_more_website_podcasts_list = async () => {
    const result = await website_podcasts_list(loadMoreLink);
    if (result.code === 200) {
      setLoadMoreLink(result.load_more);
      var newArray = websitePodcasts.concat(result.website_pods);
      setWebsitePodcasts(newArray);
      setTotalPages(result.total_pages + 1);
      setCountPages(countPages + 1);
      setIsLoadingSection(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    }
  };

  const handleChangeInputsState = (e) => {
    const { name, value } = e.target;
    setInputsState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeUploadFile = (e) => {
    const { name, files } = e.target;
    setInputsState((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const update_load_more_link = () => {
    setIsLoadingMore(true);
    get_more_website_podcasts_list(loadMoreLink);
  };

  useEffect(() => {
    get_more_website_podcasts_list();
  }, []);

  const handleSubmitFree = async (event) => {
    event.preventDefault();
    setIsLoadingCard(true);
    let upload_logo_link = "";
    let upload_headshot_link = "";
    if (inputState.uploadLogo) {
      const formData1 = new FormData();
      formData1.append("image", inputState.uploadLogo);
      formData1.append("width", 1000);
      formData1.append("height", 1000);
      const result1 = await update_contact_image_on_s3(formData1);
      upload_logo_link = s3baseUrl + result1.image_path;
    }
    if (inputState.uploadHeadshot) {
      const formData2 = new FormData();
      formData2.append("image", inputState.uploadHeadshot);
      formData2.append("width", 1000);
      formData2.append("height", 1000);
      const result2 = await update_contact_image_on_s3(formData2);
      upload_headshot_link = s3baseUrl + result2.image_path;
    }

    const formData = new FormData();
    formData.append(
      "message",
      `<p><b>Name: </b> ${inputState.name}</p>
      <p><b>Email :</b> ${inputState.email}</p>
      <p><b>Company Name :</b> ${inputState.companyName}</p>
      <p><b>Company Website :</b> ${inputState.companyWebsite}</p>
      <p><b>IG Handle :</b> ${inputState.IGHandle}</p>
      <p><b>FB Handle :</b> ${inputState.FBHandle}</p>
      <p><b>Linkedin Handle :</b> ${inputState.linkedinHandle}</p>
      <p><b>Youtube URL :</b> ${inputState.youtubeURL}</p>
      <p><b>Pinterest URL :</b> ${inputState.pinterestURL}</p>
      <p><b>Size of Email List :</b> ${inputState.sizeofEmail}</p>
      <p><b>Short Bio :</b> ${inputState.shortBio}</p>
      <p><b>What would you like to speak about on this podcast episode? :</b> ${inputState.aboutPodcastEpisode}</p>
      <p><b>Where will you promote this podcast episode?  :</b> ${inputState.promoteEpisode}</p>
      <p><b>Do you have other ways that Danielle can collaborate with you to make it a win/win for all?  :</b> ${inputState.danielleCollaborate}</p>
      <p><b>Upload logo/branding here :</b> ${upload_logo_link}</p>
      <p><b>Upload headshot here :</b> ${upload_headshot_link}</p>
      `
    );
    formData.append("page_slug", pageData.sale_page_title_slug);
    formData.append("email", inputState.email);
    const result = await send_email_by_page_slug(formData);

    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar(result.message, {
        variant: "success",
      });
      setInputsState({
        name: "",
        companyName: "",
        companyWebsite: "",
        IGHandle: "",
        youtubeURL: "",
        pinterestURL: "",
        sizeofEmail: "",
        linkedinHandle: "",
        FBHandle: "",
        email: "",
        promoteEpisode: "",
        shortBio: "",
        aboutPodcastEpisode: "",
        danielleCollaborate: "",
        uploadHeadshot: "",
        uploadLogo: "",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  return (
    <div className="landing-page">
      <WebHeader data={pageData} setIsLoading={setIsLoading} />
      <section
        className="web_hero-section web-margin-after-header"
        style={{
          background: `url(${
            s3baseUrl + pageData.sale_page_detail.banner_image
          }) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 banner-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.banner_text,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <section className="web_success-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 proposetry-image">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.sale_page_detail.text_under_banner,
                  }}
                ></div>
              </div>
            </div>
            <div className="col-xl-9 mx-auto">
              <div className="row justify-content-center web_mt-96">
                {websitePodcasts &&
                  websitePodcasts.map((podcasts, i) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-4 web_mb-40 proposetry-image">
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/${pageData.sale_page_title_slug}/${podcasts.room_slug}`,
                              {
                                state: podcasts,
                              }
                            )
                          }
                        >
                          {podcasts.video_url ? (
                            <ReactVideoPlayer url={podcasts.video_url} />
                          ) : (
                            <img
                              src={s3baseUrl + podcasts.room_image.thumbnail_1}
                              alt=""
                              className="img-fluid"
                            />
                          )}
                          <p>{htmlDecode(podcasts.title)}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {countPages < totalPages && (
              <div className="web_episode-btn">
                {isLoadingMore ? (
                  <button>LOADING EPISODES ...</button>
                ) : (
                  <button onClick={update_load_more_link}>
                    {htmlDecode(pageData.sale_page_detail.button_text)} &nbsp;
                    &nbsp;
                    <i className="fa-solid fa-angle-right" />
                  </button>
                )}
              </div>
            )}
          </div>
        </section>
      )}
      <section className="web_awareness_wrapper bg-light text-center">
        <div className="container">
          <div className="row justify-content-center podcast-form">
            <div className="col-xl-8">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.form_top_text,
                }}
              ></div>
              <form onSubmit={handleSubmitFree}>
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputState.name}
                      required
                      onChange={handleChangeInputsState}
                      placeholder="NAME *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      required
                      value={inputState.companyName}
                      onChange={handleChangeInputsState}
                      placeholder="Company Name *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="companyWebsite"
                      required
                      value={inputState.companyWebsite}
                      onChange={handleChangeInputsState}
                      placeholder="Company Website *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="IGHandle"
                      required
                      value={inputState.IGHandle}
                      onChange={handleChangeInputsState}
                      placeholder="IG Handle *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="FBHandle"
                      required
                      value={inputState.FBHandle}
                      onChange={handleChangeInputsState}
                      placeholder="FB Handle *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="linkedinHandle"
                      required
                      value={inputState.linkedinHandle}
                      onChange={handleChangeInputsState}
                      placeholder="Linkedin Handle *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="youtubeURL"
                      required
                      value={inputState.youtubeURL}
                      onChange={handleChangeInputsState}
                      placeholder="Youtube URL *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="text"
                      className="form-control"
                      name="pinterestURL"
                      required
                      value={inputState.pinterestURL}
                      onChange={handleChangeInputsState}
                      placeholder="Pinterest URL *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="number"
                      className="form-control"
                      name="sizeofEmail"
                      required
                      value={inputState.sizeofEmail}
                      onChange={handleChangeInputsState}
                      placeholder="Size of Email List *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <input
                      type="email"
                      name="email"
                      required
                      className="form-control"
                      value={inputState.email}
                      onChange={handleChangeInputsState}
                      placeholder="EMAIL *"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 text-start">
                    <label for="uploadLogo" class="btn">
                      Upload logo/branding here
                    </label>
                    <input
                      type="file"
                      id="uploadLogo"
                      name="uploadLogo"
                      className="form-control"
                      // value={inputState.uploadLogo}
                      onChange={handleChangeUploadFile}
                      placeholder="Upload logo/branding here:"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 text-start">
                    <label for="uploadHeadshot" class="btn">
                      Upload headshot here
                    </label>
                    <input
                      type="file"
                      id="uploadHeadshot"
                      name="uploadHeadshot"
                      className="form-control"
                      // value={inputState.uploadHeadshot}
                      onChange={handleChangeUploadFile}
                      placeholder="Upload headshot here: "
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <textarea
                      className="form-control h-auto"
                      rows={5}
                      name="shortBio"
                      required
                      value={inputState.shortBio}
                      onChange={handleChangeInputsState}
                      placeholder="Short Bio *"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <textarea
                      className="form-control h-auto"
                      rows={5}
                      name="aboutPodcastEpisode"
                      required
                      value={inputState.aboutPodcastEpisode}
                      onChange={handleChangeInputsState}
                      placeholder="What would you like to speak about on this podcast episode? *"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <textarea
                      className="form-control h-auto"
                      rows={5}
                      name="promoteEpisode"
                      required
                      value={inputState.promoteEpisode}
                      onChange={handleChangeInputsState}
                      placeholder="Where will you promote this podcast episode? *"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-lg-5">
                    <textarea
                      className="form-control h-auto"
                      rows={5}
                      name="danielleCollaborate"
                      required
                      value={inputState.danielleCollaborate}
                      onChange={handleChangeInputsState}
                      placeholder="Do you have other ways that Danielle can collaborate with you to make it a win/win for all? *"
                      defaultValue={""}
                    />
                  </div>
                  <div className="wb_book_btn">
                    {isLoadingCard ? (
                      <button disabled={true}>Submitting...</button>
                    ) : (
                      <button type="submit">
                        {pageData.sale_page_detail.form_button_text}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <WebFooter data={pageData} setIsLoading={setIsLoading} />
    </div>
  );
}
