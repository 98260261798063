import { seedValue } from "faker";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import RequestsList from "../PaymentRequests/RequestsList";
import RequestCard from "../PaymentRequests/RequestCard";
import TransactionCard from "../PaymentRequests/TransactionCard";
import { CircularProgress } from "@mui/material";
import { all_transaction_list } from "src/DAL/Transaction/Transaction";
import { RecordNotFound } from "../_dashboard/programmes";

const billing = [
  {
    id: 1,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    month: "10",
    request_type: "onetime",
    currency: "usd",
  },
  {
    id: 2,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    request_type: "recurring",
    currency: "usd",
    month: "5",
  },
  {
    id: 3,
    request_title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    createdAt: "Jan, 17 2022",
    total_amount: "9000",
    initial_amount: "9000",
    installment_amount: "9000",
    request_type: "recurring",
    currency: "usd",
    month: "6",
  },
];

const AllTransaction = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState(true);

  const handlePage = (slug) => {
    navigate(`/active-request/${slug}`);
  };

  const getTransactions = async () => {
    const result = await all_transaction_list();
    if (result.code === 200) {
      setTransactions(result.transaction);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  if (isLoading) {
    return <CircularProgress className="circular-progress" color="primary" />;
  }

  return (
    <div className="container transaction-screen">
      <h2 className="mb-3">All Transaction</h2>
      <div className="col-12">
        {transactions.length > 0 ? (
          transactions.map((transaction, index) => {
            return (
              <TransactionCard
                handlePage={handlePage}
                btn_title="View Request"
                transaction={transaction}
              />
            );
          })
        ) : (
          <RecordNotFound title="Transactions" />
        )}
      </div>
    </div>
  );
};

export default AllTransaction;
