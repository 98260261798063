import { invokeApi } from "../../bl_libs/invokeApi";

export const NinteenDayQuestionList = async (data) => {
  const requestObj = {
    path: `api/ninteen_day_vision_questions/get_question`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const EarningListApi = async (data) => {
  const requestObj = {
    path: `api/member_earning/get_member_earning`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const UploadGalleryImageOnS3 = async (data) => {
//   const requestObj = {
//     path: `api/goal_statement_question/upload_gallery_images/`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

export const AddNineteenDay = async (data) => {
  const requestObj = {
    path: `api/member/nineteen_day_vision`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditNinetyDayEarning = async (data, id) => {
  const requestObj = {
    path: `api/member_earning/edit_member_earning/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddnineteenDayNewEarning = async (data) => {
  const requestObj = {
    path: `api/member_earning/add_member_earning`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteEarning = async (id) => {
  const requestObj = {
    path: `api/member_earning/delete_member_earning/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const AddSingleAnswerApi = async (data) => {
//   const requestObj = {
//     path: `api/member/update_goal_statement_question/`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const AddAllAnswersApi = async (data) => {
//   const requestObj = {
//     path: `api/member/update_all_goal_statement_question/`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
