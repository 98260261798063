import { websitePagesContent } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomePage from "./HomePage";
import { useParams } from "react-router-dom";
import AboutPage from "./AboutPage";
import ProgramsList from "./ProgramsList";
import EventsPage from "./EventsPage";
import WebPodcasts from "./WebPodcasts";
import TIRCoachingMentorship from "./TIRCoachingMentorship";
import TIRFundamentals from "./TIRFundamentals";
import TIRLeadershipCoaching from "./TIRLeadershipCoaching";
import TIRJunior from "./TIRJunior";
import LeadFieldProgram from "./LeadFieldProgram";
import FrequencyOfMoney from "./FrequencyOfMoney";
import MoneyManifestor from "./MoneyManifestor";
import AbundanceAccelerator from "./AbundanceAccelerator";
import MoneyBundle from "./MoneyBundle";
import CommunityPage from "./CommunityPage";
import PaymentPage from "./PaymentPage";
import EmpressExperience from "./EmpressExperience";
import Page404 from "../Page404";
import MediaPage from "./MediaPage";
import ArtOfInfiniteIntuition from "./ArtOfInfiniteIntuition";
import AwakeningInfiniteIntuition from "./AwakeningInfiniteIntuition";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import ExpanseMastermind from "./ExpanseMastermind";
import SuccessHabits from "./SuccessHabits";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LandingPage() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageName, setPageName] = useState("");
  const [testimonials, setTestimonials] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [whatIncluded, setWhatIncluded] = useState([]);
  const classes = useStyles();

  const getWebsitePagesContent = async () => {
    var get_page_slug = {};
    if (params.page_slug) {
      get_page_slug = { page_slug: params.page_slug };
    } else {
      get_page_slug = { page_slug: "" };
    }
    const result = await websitePagesContent(JSON.stringify(get_page_slug));
    if (result.code === 200) {
      console.log(result.is_what_include, "result");
      setPageData(result.Sale_page);
      setPageName(result.Sale_page.page_component_name);
      setTestimonials(result.testimonial);
      setFaqData(result.faq);
      setEventsData(result.event);
      setPaymentPlan(result.payment_plan);
      setWhatIncluded(result.is_what_include);
      setIsLoading(false);
    } else {
      setPageName(undefined);
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWebsitePagesContent();
  }, [params.page_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {pageName === "" || pageName === "home" ? (
        <HomePage
          pageData={pageData}
          testimonials={testimonials}
          setIsLoading={setIsLoading}
        />
      ) : pageName === "about" ? (
        <AboutPage pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "programs" ? (
        <ProgramsList pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "events" ? (
        <EventsPage
          pageData={pageData}
          setIsLoading={setIsLoading}
          eventsData={eventsData}
          testimonials={testimonials}
        />
      ) : pageName === "podcast" ? (
        <WebPodcasts pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "web-community" ? (
        <CommunityPage pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "contact-us" ? (
        <PaymentPage pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "12-months-empress-experience" ? (
        <EmpressExperience
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "thinking-into-results-coaching-mentorship" ? (
        <TIRCoachingMentorship
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "thinking-into-results-fundamentals" ? (
        <TIRFundamentals
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "thinking-into-results-elite-leadership-coaching" ? (
        <TIRLeadershipCoaching
          pageData={pageData}
          setIsLoading={setIsLoading}
          testimonials={testimonials}
          faqData={faqData}
          paymentPlan={paymentPlan}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "thinking-into-results-junior" ? (
        <TIRJunior
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "lead-the-field" ? (
        <LeadFieldProgram
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "frequency-of-money" ? (
        <FrequencyOfMoney
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "money-manifestor" ? (
        <MoneyManifestor
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "abundance-accelerator" ? (
        <AbundanceAccelerator
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "money-bundle" ? (
        <MoneyBundle
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
        />
      ) : pageName === "media" ? (
        <MediaPage pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "art-of-infinite-intuition" ? (
        <ArtOfInfiniteIntuition
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "awakening-infinite-intuition" ? (
        <AwakeningInfiniteIntuition
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "expanse-mastermind" ? (
        <ExpanseMastermind
          pageData={pageData}
          setIsLoading={setIsLoading}
          paymentPlan={paymentPlan}
          testimonials={testimonials}
          faqData={faqData}
          whatIncluded={whatIncluded}
        />
      ) : pageName === "privacy-policy" ? (
        <PrivacyPolicy pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "terms-of-use" ? (
        <TermsAndConditions pageData={pageData} setIsLoading={setIsLoading} />
      ) : pageName === "success-habits" ? (
        <SuccessHabits pageData={pageData} setIsLoading={setIsLoading} />
      ) : (
        <Page404 />
      )}
    </>
  );
}
export default LandingPage;
