import {
  get_member_event_detail_by_slug,
  token_generate_for_upgrade_portal_api,
} from "src/DAL/LiveEvents/LiveEvents";
import { websiteBaseUrl } from "src/config/config";

export const get_live_event_detail = async (
  event_slug,
  setIsEventAvailable,
  category_slug,
  setValue,
  setEventDetail,
  setCurrentEventsArray,
  setUpcomingEventsArray,
  setShowChat,
  setIsLoading,
  setLiveChat,
  setPinnedComments,
  setNewChat,
  setShowScrollArrow,
  userInfo
) => {
  const result = await get_member_event_detail_by_slug(event_slug);
  if (result.code === 200) {
    setIsEventAvailable(true);
    if (category_slug) {
      result.member_dynamite_event.dynamite_event_category.map(
        (category, index) => {
          if (category_slug === category.dynamite_event_category_slug) {
            setValue(index + 1);
          }
        }
      );
    }
    setEventDetail(result.member_dynamite_event);
    setCurrentEventsArray(result.current_events_array);
    setUpcomingEventsArray(result.upcoming_events_array);
    if (
      result.member_dynamite_event.feature_video?.is_feature &&
      result.member_dynamite_event.feature_video?.is_chat_enable
    ) {
      setShowChat(true);
      get_live_event_chat(
        result.member_dynamite_event.feature_video._id,
        true,
        setLiveChat,
        setPinnedComments,
        setNewChat,
        setShowScrollArrow,
        userInfo,
        setIsLoading
      );
    } else {
      setShowChat(false);
      setIsLoading(false);
    }
  } else {
    setIsEventAvailable(false);
    setIsLoading(false);
  }
};
export const handle_validate_redirect = (banner_link, setIsLoading) => {
  handle_change_page(banner_link, setIsLoading);
};
export const handle_change_page = async (page_link, setIsLoading) => {
  setIsLoading(true);
  let page_url = {
    page_url: page_link,
  };
  const result = await token_generate_for_upgrade_portal_api(page_url);
  if (result.code === 200) {
    window.open(`${websiteBaseUrl}auth-redirect-controller/${result.token}`);
    setIsLoading(false);
  } else {
    setIsLoading(false);
  }
};
