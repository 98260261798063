// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  Card,
  CircularProgress,
} from "@mui/material";
// components
import Page from "src/components/Page";

import DashboardCircle from "src/components/_dashboard/app/DashboardCircle";
import { ReactVideoPlayer } from "src/components";

import { useContext, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { project_name, s3baseUrl } from "src/config/config";

import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import Disclaimer from "src/components/Disclaimer";
import { useEffect } from "react";
import {
  ProjectInfoApi,
  dashboardDetail,
  memberDetail,
} from "src/DAL/Profile/Profile";
import SummaryCard from "src/components/SummaryCard";
import ReviewCardSlider from "src/components/_dashboard/app/ReviewCards";
import ShowEvenetSlider from "src/components/_dashboard/app/EventSlider";
import { itemFeatureLayer_1, profileBg } from "src/assets";
import DashboardEventsCard from "./components/DashboardEventsCard";
import ProgramActivity from "./components/ProgramActivity";
import DashboardWebinarCard from "./components/DashboardWebinarCard";
import Iconify from "src/components/Iconify";
import ProgramAccess from "./components/ProgramAccess";
import Slider from "react-slick";
import VaultActivity from "./components/VaultActivity";
import { htmlDecode } from "src/utils/convertHtml";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
// ----------------------------------------------------------------------

export default function DashboardStates() {
  const {
    contentSettingData,
    dashboardSettingData,

    dashboardEventData,
  } = useContentSetting();
  const classes = useStyles();

  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [disclaimerDetail, setDisclaimerDetail] = useState("");
  const [disclaimerHeading, setDisclaimerHeading] = useState("");
  const [disclaimerLabel, setDisclaimerLabel] = useState("");
  const [disclaimerButton, setDisclaimerButton] = useState("");
  const [disclaimerStatus, setDisclaimerStatus] = useState("");
  const [memberData, setMemberData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [replayData, setReplayData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [webinarsData, setWebinarsData] = useState([]);
  const navigate = useNavigate();
  const is_seen_video = _get_is_seen_video_localStorage();
  // console.log(
  //   dashboardEventData.filter((data) => data.created_for == "general"),
  //   "dashboardEventData"
  // );
  console.log(dashboardSettingData, "ok");
  //Getting Project Info from API

  const CustomNextArrow = (props) => (
    <Iconify
      className="custom-next-arrow"
      onClick={props.onClick}
      icon={"ooui:next-ltr"}
      width={18}
      height={18}
    />
  );

  // Custom Previous button component
  const CustomPrevArrow = (props) => (
    <Iconify
      className="custom-prev-arrow"
      onClick={props.onClick}
      icon={"ooui:next-rtl"}
      width={18}
      height={18}
    />
  );
  let set_infinite = true;
  if (programData.length < 3) {
    set_infinite = false;
  }
  var settings = {
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    dots: false,
    autoplay: true,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      setDisclaimerDetail(result.member_setting.disclaimer_text);
      setDisclaimerStatus(result.member_setting.is_disclaimer_show);
      setDisclaimerButton(result.member_setting.disclaimer_button_text);
      setDisclaimerLabel(result.member_setting.checkbox_label);
      setDisclaimerHeading(result.member_setting.disclaimer_heading);
      if (result.member_setting.is_disclaimer_show) {
        setopenPasswordModal(true);
      }
      getMemberData();
    }
  };
  const getMemberData = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      console.log(result, "resultresultresultresult");

      setMemberData(result.member);
      getDashboarddata();
    }
  };
  const handleClick = (object) => {
    delete object.card_button;
    if (!object.locked) {
      navigate(`/live-events/${object._id}`);
    } else if (
      object.lock_configration &&
      object.lock_configration.lock_event_description !== ""
    ) {
      navigate(`/live-events-locked/${object._id}`, { state: object });
    }
  };

  const getDashboarddata = async () => {
    const result = await dashboardDetail();
    if (result.code === 200) {
      console.log(result, "resultresultresultresult");
      setReplayData(result.data.replay_library);

      setUpcomingData(result.data.upcoming_events);
      setProgramData(result.data.programs);
      let CARD_OPTIONS = [];

      result.data.webinars.map((dynamite_event) => {
        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          card_button: dynamite_event.locked === true && {
            button_title: (
              <>
                {/* <LockIcon />
                  {` Unlock Access`} */}

                {dynamite_event.lock_configration ? (
                  dynamite_event.lock_configration.lock_event_logo ? (
                    <img
                      src={
                        s3baseUrl +
                        dynamite_event.lock_configration.lock_event_logo
                      }
                      width={20}
                      className="me-1"
                    />
                  ) : (
                    <LockIcon className="lock-icon-color" />
                  )
                ) : (
                  <>
                    <LockIcon className="lock-icon-color" />
                  </>
                )}
                {dynamite_event.lock_configration &&
                dynamite_event.lock_configration.lock_event_button_text
                  ? dynamite_event.lock_configration.lock_event_button_text
                  : "Unlock Access"}
              </>
            ),
            handleClick: handleClick,
          },
        });
      });
      setWebinarsData(CARD_OPTIONS);

      let recentData = result.data.recent_activity.map((records) => {
        console.log(records, "dfkasjdl");
        const inputString = records.video_section_type;
        const parts = inputString.split("_");

        const formattedString = parts
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
          .join(" ");

        return {
          ...records,
          section: formattedString,
        };
      });
      setRecentData(recentData);
      console.log(recentData, "recentData", result.data.replay_library);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getProjectInfo();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    // <Page title="Dashboard ">
    <div className="container">
      <div className="row">
        <div className="col-12 dashboard_description">
          {contentSettingData && contentSettingData.dashboard_description ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData.dashboard_description,
              }}
            ></div>
          ) : (
            <h2 className="dashboard-heading">
              {dashboardSettingData.wellcome_text}
            </h2>
          )}
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12 mt-5 mx-auto text-center">
          <DashboardCard data={dashboardSettingData} />
        </div> */}
        {(project_name == "DD" ||
          project_name == "BASIC_DEV" ||
          project_name == "PGI_DEV") && (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <DashboardCircle data={dashboardSettingData} />
            </div>
          </>
        )}
      </div>
      <div class=" mt-2 ">
        <div class="card">
          <div class="row no-gutters">
            <div class="col-lg-6">
              <ReactVideoPlayer url={dashboardSettingData.video_url} />
              {/* <img
                src={profileBg}
                class="card-img"
                width="100%"
                height="100%"
                alt="Image"
              /> */}
            </div>

            <div class="col-lg-6">
              <div
                class="d-flex flex-column justify-content-center align-items-center"
                style={{ padding: "2rem 2rem 2rem 1rem" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: dashboardSettingData.welcome_image,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3"></div>
      {programData.length > 0 && (
        <div class=" mt-3 ">
          <div className="">
            <div className="d-flex align-items-center m-1 mb-3">
              <div className="heading me-3">
                <Iconify icon={"bxs:file"} width={40} height={40} />
              </div>
              <div className="text-start ">
                <div>
                  <Typography
                    className="heading"
                    sx={{ fontWeight: 600, fontSize: 20 }}
                  >
                    Programs
                  </Typography>
                </div>
              </div>
            </div>

            {/* <div
            className="d-flex w-100 no-scroll"
            style={{ overflowX: "scroll" }}
          > */}
            <Slider {...settings}>
              {programData.map((item, i) => (
                <div className="me-5">
                  <ProgramAccess
                    title={item.title}
                    count="10"
                    icon={item.program_images?.thumbnail_1}
                    short_description={item.short_description}
                    no_of_lesson={item.no_of_lesson}
                    total_lesson_duration={item.total_lesson_duration}
                  />
                </div>
              ))}
            </Slider>

            {/* </div> */}
            {/* <Stack
            direction={{ sm: "row", md: "row", xs: "row" }}
            spacing={3}
            sx={{ mb: 2, overflow: "hidden" }}
          >
            <Grid
              container
              spacing={3}
              // sx={{ width: { sm: "auto", md: "75%" } }}

              // width={{}}
            >
              {StatesData.map((item, i) => (
                <Grid key={i} item xs={12} sm={12} md={4} lg={4}>
                  <ProgramAccess
                    color={item.color}
                    title={item.title}
                    count={item.count}
                    icon={item.icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack> */}
          </div>
        </div>
      )}
      <div className="p-3"></div>
      <div class="row no-gutters">
        {replayData.length > 0 && (
          <div class={`${recentData.length > 0 ? "col-lg-6" : ""}`}>
            <div class=" mt-3 ">
              <div className="">
                <div className="d-flex align-items-center  mb-3">
                  <div className="heading me-3">
                    <Iconify
                      icon={"material-symbols:library-books"}
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className=" text-start ">
                    <div>
                      <Typography
                        className="heading"
                        sx={{ fontWeight: 600, fontSize: 20 }}
                      >
                        Replay Library
                      </Typography>
                    </div>
                  </div>
                </div>

                <VaultActivity replayData={replayData} />
              </div>
            </div>
          </div>
        )}
        {recentData.length > 0 && (
          <div class={`${replayData.length > 0 ? "col-lg-6" : ""}`}>
            <div class=" mt-3 ">
              <div className="">
                <div className="d-flex align-items-center  mb-3">
                  <div className="heading me-3">
                    <Iconify
                      icon={"fluent-mdl2:account-activity"}
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className=" text-start ">
                    <div>
                      <Typography
                        className="heading"
                        sx={{ fontWeight: 600, fontSize: 20 }}
                      >
                        Last Activity
                      </Typography>
                    </div>
                  </div>
                </div>

                <ProgramActivity recentData={recentData} />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* upcoming events */}
      {/* <div className="p-3"></div> */}

      {/* <div class=" mt-3">
        <div className="">
          <div className="d-flex align-items-center m-1 mb-3 ">
            <div className="heading me-3">
              <Iconify icon={"mdi:events"} width={40} height={40} />
            </div>
            <div className=" text-start ">
              <div>
                <Typography
                  className="heading"
                  sx={{ fontWeight: 600, fontSize: 20 }}>
                  Upcoming Events
                </Typography>
              </div>
            </div>
          </div>
          <Stack
            direction={{ sm: "column", md: "row", xs: "column" }}
            spacing={3}
            // sx={{ mb: 2, mt: 2 }}
          >
            <Grid
              container
              spacing={3}
              // sx={{ width: { sm: "auto", md: "75%" } }}

              // width={{}}
            >
              {upcomingData.map((item, i) => (
                <Grid key={i} item xs={12} sm={12} md={6} lg={6}>
                  <DashboardEventsCard
                    color={item.color}
                    title={item.title}
                    description={item.description}
                    endTime={item.endTime}
                    recurring_type={item.recurring_type}
                    start_date={item.start_date}
                    start_time={item.start_time}
                    end_time={item.end_time}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </div>
      </div> */}
      <div className="p-3"></div>

      {webinarsData.length > 0 && (
        <div class="mt-3">
          <div className="">
            <div className="d-flex align-items-center  mb-3 ">
              <div className="heading me-3">
                <Iconify icon={"ic:round-live-tv"} width={40} height={40} />
              </div>
              <div className=" text-start ">
                <div>
                  <Typography
                    className="heading"
                    sx={{ fontWeight: 600, fontSize: 20 }}
                  >
                    Webinar
                  </Typography>
                </div>
              </div>
            </div>

            {webinarsData.length > 0 && (
              <DashboardWebinarCard
                endTime="17:05"
                data={webinarsData}
                handleClick={handleClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
    // </Page>
  );
}
