import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { DeleteEvent } from "src/DAL/Calender/Calender";
import EditEvent from "./EditEvent";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
export default function EventDetail({
  eventDetailData,
  setIsLoading,
  onCloseDrawer,
  dataList,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [eventSlug, setEventSlug] = useState("");
  const [editValues, setEditValues] = useState({});
  const [showEditComponent, setShowEditComponent] = useState(false);
  const { userInfo, adminTimeZone } = useContentSetting();
  const handleEdit = (value) => {
    setShowEditComponent(true);
  };
  const handleAgreeDelete = (value) => {
    setEventSlug(value);
    setOpenDelete(true);
  };

  //Deleting event
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeleteEvent(eventSlug);
    if (result.code === 200) {
      setIsLoading(false);
      dataList();
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/calendar");
      onCloseDrawer();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    setEditValues(eventDetailData);
  }, []);

  console.log(eventDetailData, "eventDetailData");

  return (
    <>
      {showEditComponent ? (
        <EditEvent
          editValues={editValues}
          dataList={dataList}
          setEditValues={setEditValues}
          setShowEditComponent={setShowEditComponent}
        />
      ) : (
        <div className="container event-details">
          <h1>{htmlDecode(editValues.event_title)}</h1>
          <div className="row media-margin">
            <div className="col-12 section-space">
              <div className="row">
                <div className="col-4">
                  <b>Start Date :</b>&nbsp;
                </div>
                <div className="col-8">
                  <p>{editValues.start_date}</p>
                </div>

                <div className="col-4">
                  <b>Start Time 1:</b>&nbsp;
                </div>
                <div className="col-8">
                  <p>
                    {editValues.action_by === "admin_user"
                      ? get_date_with_user_time_zone(
                          moment(editValues.date).format("YYYY-MM-DD") +
                            " " +
                            editValues.start_time,
                          "hh:mm A",
                          userInfo.time_zone,
                          adminTimeZone
                        )
                      : moment(
                          moment(editValues.date).format("YYYY-MM-DD") +
                            " " +
                            editValues.start_time
                        ).format("hh:mm A")}
                  </p>
                </div>
                <div className="col-4">
                  <b>End Time :</b>&nbsp;
                </div>
                <div className="col-8">
                  <p>
                    {editValues.action_by === "admin_user"
                      ? get_date_with_user_time_zone(
                          moment(editValues.date).format("YYYY-MM-DD") +
                            " " +
                            editValues.end_time,
                          "hh:mm A",
                          userInfo.time_zone,
                          adminTimeZone
                        )
                      : moment(
                          moment(editValues.date).format("YYYY-MM-DD") +
                            " " +
                            editValues.end_time
                        ).format("hh:mm A")}
                  </p>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: editValues.description,
                }}></div>
            </div>
          </div>
          {editValues.event_type == "individual" && (
            <div className="affirmation-dots">
              <CustomPopover
                menu={MENU_OPTIONS}
                data={eventDetailData.event_slug}
              />
            </div>
          )}
          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Event?"}
            handleAgree={handleDelete}
          />
        </div>
      )}
    </>
  );
}
