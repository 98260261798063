import { Divider } from "@material-ui/core";
import { Card } from "@mui/material";
import React from "react";
import TransactionCard from "../PaymentRequests/TransactionCard";
import TransactionTable from "../PaymentRequests/TransactionTable";

const billing = [
  {
    id: 1,
    date: "Jan, 17 2022",
    amount: "$7000",
    status: "Success",
  },
  {
    id: 2,
    date: "Jan, 18 2022",
    amount: "$8000",
    status: "Success",
  },
  {
    id: 3,
    date: "Jan, 19 2022",
    amount: "$9000",
    status: "Failed",
  },
];
const TransactionList = ({ transactions }) => {
  return (
    <>
      <h2 className="mt-5 mb-3">Transaction list</h2>
      <Card className="payment-card">
        <div className="col-12 text-center">
          <TransactionTable transactions={transactions} />
        </div>
      </Card>
    </>
  );
};
export default TransactionList;
