import "./fonts/FuturaBT-Book.woff2";
import "./fonts/FuturaStd-Book.woff";
import "./css/style.css";
import WebImageSlider from "./WebImageSlider";
import WebFooter from "./WebFooter";
import { website_program_list } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import WebHeader from "./WebHeader";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

export default function ProgramsList({ pageData, setIsLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const [programCategories, setProgramCategories] = useState([]);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const classes = useStyles();

  const getWebsiteProgramList = async () => {
    const result = await website_program_list();
    if (result.code === 200) {
      setProgramCategories(result.program_category);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
    }
  };

  useEffect(() => {
    getWebsiteProgramList();
  }, []);

  return (
    <div className="landing-page">
      <WebHeader data={pageData} setIsLoading={setIsLoading} />
      <section
        className="web_hero-section web-margin-after-header"
        style={{
          background: `url(${
            s3baseUrl + pageData.sale_page_detail.banner_image
          }) no-repeat center center`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 banner-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.banner_text,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <section className="web_about_wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="row justify-content-center">
                  {programCategories &&
                    programCategories.map((category, i) => {
                      return (
                        <>
                          <div className="ps-4 mt-4 pt-2 category-title">
                            <p>{htmlDecode(category.heading_text)}</p>
                          </div>
                          <div className="row">
                            {category.website_programme.map((programme, j) => {
                              return (
                                <div className="col-md-6 mt-2">
                                  <a href={programme.program_link}>
                                    <div className="position-relative">
                                      <img
                                        src={
                                          s3baseUrl +
                                          programme.program_images.thumbnail_1
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                      <div className="wb_program-inner">
                                        <p>{htmlDecode(programme.title)}</p>
                                        <p>
                                          {htmlDecode(programme.program_author)}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="web_awareness_wrapper wb_para-style">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 starting-point">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.book_call_section_text,
                }}
              ></div>
              <div className="learn-btn">
                <a href={pageData.sale_page_detail.book_call_button_link}>
                  <button>
                    {htmlDecode(
                      pageData.sale_page_detail.book_call_button_text
                    )}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WebFooter data={pageData} setIsLoading={setIsLoading} />
    </div>
  );
}
