import "./fonts/FuturaBT-Book.woff2";
import "./fonts/FuturaStd-Book.woff";
import "./css/style.css";
import BrandLogoSlider from "./BrandLogoSlider";
import WebFooter from "./WebFooter";
import { makeStyles } from "@mui/styles";
import { website_media_section } from "src/DAL/WebPages/WebPages";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import WebHeader from "./WebHeader";
import WebImageSlider from "./WebImageSlider";
import { CircularProgress } from "@mui/material";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

export default function MediaPage({ pageData, setIsLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [podcastMediaList, setPodcastMediaList] = useState([]);
  const [newsLetterMediaList, setNewsLetterMediaList] = useState([]);
  const [brandMediaList, setBrandMediaList] = useState([]);
  const [isLoadingSection, setIsLoadingSection] = useState(true);

  const get_website_media_section = async () => {
    const result = await website_media_section();
    if (result.code === 200) {
      setPodcastMediaList(result.podcast_media_section);
      setNewsLetterMediaList(result.newsletter_media_section);
      setBrandMediaList(result.brand_media_section);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
    }
  };

  useEffect(() => {
    get_website_media_section();
  }, []);

  return (
    <div className="landing-page media-page">
      <WebHeader data={pageData} setIsLoading={setIsLoading} />
      <section
        className="web_hero-section text-dark web-margin-after-header"
        style={{
          background: `url(${
            s3baseUrl + pageData.sale_page_detail.banner_image
          }) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 media-banner banner-black-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.sale_page_detail.banner_text,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <>
          <section className="web_ready-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        pageData.sale_page_detail.top_section_after_banner_text,
                    }}
                  ></div>
                  <div></div>
                  <div className="row media-people">
                    {podcastMediaList &&
                      podcastMediaList.map((podcast_media, i) => {
                        return (
                          <div className="col-md-3 col-lg-3 pt-80">
                            <a
                              href={podcast_media.button_link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={s3baseUrl + podcast_media.image}
                                alt=""
                                className="img-fluid mx-auto"
                              />
                              <h4>{htmlDecode(podcast_media.title)}</h4>
                              <p>
                                {htmlDecode(podcast_media.short_description)}
                              </p>
                            </a>
                          </div>
                        );
                      })}
                  </div>
                  <div className="border-top-div" />
                </div>
              </div>
            </div>
          </section>
          <section className="web_included_wrapper bg-white pt-0">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 text-center text-md-start">
                  <div className="text-center">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          pageData.sale_page_detail.news_letter_section_heading,
                      }}
                    ></div>
                  </div>
                  {newsLetterMediaList &&
                    newsLetterMediaList.map((news_letter, i) => {
                      return (
                        <>
                          <div className="row mt-0 mt-md-5 align-items-center">
                            <div className="col-md-4 media-image">
                              <a
                                href={news_letter.button_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={s3baseUrl + news_letter.image}
                                  alt=""
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                            <div className="col-md-8 ps-lg-5">
                              <div className="mb-3">
                                <h2>{htmlDecode(news_letter.title)}</h2>
                              </div>
                              <p>{htmlDecode(news_letter.short_description)}</p>
                              {news_letter.button_text && (
                                <div className="learn-btn mb-4">
                                  <a
                                    href={news_letter.button_link}
                                    target="_blank"
                                  >
                                    <button>
                                      {htmlDecode(news_letter.button_text)}
                                    </button>
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                          {newsLetterMediaList.length > i + 1 && <hr />}
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="pt-80">
                <BrandLogoSlider data={brandMediaList} />
              </div>
            </div>
          </section>
        </>
      )}

      {/* <WebImageSlider data={pageData} /> */}
      <WebFooter data={pageData} setIsLoading={setIsLoading} />
    </div>
  );
}
