import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RatingCard from "../../components/_dashboard/programmes/RatingCard";
import ProgrammDetailTab from "../../components/_dashboard/programmes/ProgrammDetailTab";
import { programmDetail } from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoDurationPlayer } from "src/components";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { userInfo, adminTimeZone, handleVideoList } = useContentSetting();

  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState([]);
  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });
  const handleSetLocal = (value) => {
    // console.log(value, "dfklajsd", programmesDetail);
    let dataKey = programmesDetail?._id;
    let _url = get_programme_video();
    let user_id = userInfo?._id;
    let user_Info = localStorage.getItem(`${user_id}`);
    let data = {};
    if (user_Info) {
      data = JSON.parse(user_Info);
    }
    data[dataKey] = {
      url: _url,
      playedSec: value,
    };

    // console.log("sdfgjskdlk", JSON.parse(user_Info));
    localStorage.setItem(`${user_id}`, JSON.stringify(data));
  };
  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.slug);
    if (result.code === 200) {
      let data = [
        {
          recording_id: result.program?._id,
          video_duration: result.program?.video_duration,
          total_video_duration: result.program?.total_video_duration,
          is_complete: result.program?.is_complete,
        },
      ];
      setVideoProgressData(data);
      handleVideoList(data);
      // localStorage.setItem("video_list", JSON.stringify(data));
      localStorage.setItem("video_type", "program");
      setProgrammesDetail(result.program);
      setLessonsList(result.lesson);
      setResourcesList(result.program_document);
      setReviewsList(result.program_review);
      setRecordingList(result.recording_list);
      setProgramSection(result.program_section);
      setStudySession(result.study_session);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_programme_image = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.image;
    } else {
      return programmesDetail.program_images.thumbnail_1;
    }
  };
  const get_programme_video = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.video_url;
    } else {
      return programmesDetail.video_url;
    }
  };
  const get_audio_url = () => {
    if (programmesDetail.program_lock_status === true) {
      return "";
    } else {
      return programmesDetail.audio_file;
    }
  };

  const get_detailed_description = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.detailed_description;
    } else {
      return programmesDetail.detailed_description;
    }
  };
  useEffect(() => {
    getProgrammesDetail();
  }, []);
  useEffect(() => {
    let data = [
      {
        recording_id: programmesDetail?._id,
        video_duration: programmesDetail?.video_duration,
        total_video_duration: programmesDetail?.total_video_duration,
        is_complete: programmesDetail?.is_complete,
      },
    ];
    localStorage.setItem("video_list", JSON.stringify(data));
  }, [programmesDetail]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/programmes")}>
            <ArrowBackIcon />
          </IconButton>

          {programmesDetail.program_lock_status === false ? (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => navigate(`/programmes/${params.slug}/lessons`)}>
              View Lessons
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled">
              View Lessons
            </button>
          )}
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h1>
            {location.state
              ? htmlDecode(location.state.title)
              : htmlDecode(programmesDetail.title)}
          </h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {get_programme_video() ? (
            <ReactVideoDurationPlayer
              url={get_programme_video()}
              type={"program"}
              value={programmesDetail}
              setVideoProgressData={setVideoProgressData}
              videoProgressData={videoProgressData}
            />
          ) : get_programme_image() ? (
            <ProgrammesImgStyle src={s3baseUrl + get_programme_image()} />
          ) : (
            ""
          )}
        </div>
        <div className="col-12 text-end">
          {programmesDetail.is_complete ? (
            <Icon
              icon="mdi:tick-circle"
              width={30}
              height={30}
              color="primary"
            />
          ) : (
            <></>
          )}
        </div>
        {get_audio_url() && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={s3baseUrl + get_audio_url()}
              controls
            />
          </div>
        )}

        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: get_detailed_description(),
            }}></div>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <ProgrammDetailTab
            lessons={lessonsList}
            resources={resourcesList}
            reviews={reviewsList}
            recordingList={recordingList}
            programSection={programSection}
            studySession={studySession}
            programLockStatus={programmesDetail.program_lock_status}
            programSlug={programmesDetail.program_slug}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesDetail;
