import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";
import { get_root_value } from "src/utils/domUtils";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      {/* <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography> */}
      <Typography
        sx={{
          backgroundColor: get_root_value("--background-secondary-color"),

          color: get_root_value("--audio-color"),
        }}
        variant="h5"
        align="center"
      >
        No results found
        {/* for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words. */}
      </Typography>
    </Paper>
  );
}
