import { Dialog } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function DetailPopUpModel({
  open,
  title,
  setOpen,
  componentToPassDown,
  eventDetailData,
  handleAgreeDelete,
  handleEdit,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
          className: "p-3 general-popup-model",
        }}
      >
        <div class="icon-event-detail d-flex" onClick={() => setOpen(false)}>
          {eventDetailData.action_by == "member_user" && (
            <>
              <div className="other-detail-icon" onClick={handleEdit}>
                <EditIcon />
              </div>
              <div className="other-detail-icon" onClick={handleAgreeDelete}>
                <DeleteOutlineIcon />
              </div>
            </>
          )}
          <div className="close-detail-icon" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
        </div>
        {/* <div className="popup-title">
          <h2 className="mb-0">{title}</h2>
        </div> */}
        {/* <hr /> */}
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
