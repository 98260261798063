import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import { convertCurrencyToSign } from "src/utils/constants";

const columns = [
  { field: "id", headerName: "No", width: 100 },
  { field: "date", headerName: "Date", width: 200 },
  // { field: "currency", headerName: "Currency", width: 200 },
  { field: "amount", headerName: "Amount", width: 200 },
  { field: "status", headerName: "Status", width: 200 },
];

export default function TransactionTable({ transactions }) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getFormattedData = () => {
    const list = transactions.map((transaction, i) => {
      return {
        id: i + 1,
        date: moment(transaction.transaction_date).format("DD-MM-YYYY"),
        currency: transaction.currency.toUpperCase(),
        amount:
          convertCurrencyToSign(transaction.currency) + transaction.amount,
        status: capitalizeFirstLetter(transaction.transaction_status),
      };
    });
    return list;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        className="data-table svg-color text-center "
        rows={getFormattedData()}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnFilter={true}
        disableColumnMenu={true}
      />
    </div>
  );
}
