import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { EditNinetyDayEarning } from "../../DAL/90Day/NinetyDay";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import { convertCurrencyToSign } from "src/utils/constants";
import { InputAdornment } from "@mui/material";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import NumberFormat from "react-number-format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const EditEarning = ({ onCloseDrawer, editObject, dataList, targetDate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    earning: "",
    date: new Date(),
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const Dateconvert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const user_profile = _get_user_from_localStorage();
  const addDays = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 90);

    return moment(result).format("DD MMM YYYY");
  };

  const handleChange = (event) => {
    const inputName = event.target.name;
    const value = event.target.value;
    setInputs((inputs) => ({
      ...inputs,
      [inputName]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const startDate = new Date(targetDate);
    const currentDate = new Date(inputs.date);

    const startDateDate = startDate.getDate();
    const startDateMonth = startDate.getMonth();
    const startDateYear = startDate.getFullYear();

    const currentDateDate = currentDate.getDate();
    const currentDateMonth = currentDate.getMonth();
    const currentDateYear = currentDate.getFullYear();

    let is_valid_date = false;
    if (currentDateYear > startDateYear) {
      is_valid_date = true;
    } else if (
      currentDateYear >= startDateYear &&
      currentDateMonth > startDateMonth
    ) {
      is_valid_date = true;
    } else if (
      currentDateYear >= startDateYear &&
      currentDateMonth >= startDateMonth &&
      currentDateDate >= startDateDate
    ) {
      is_valid_date = true;
    }

    setIsLoading(true);
    if (is_valid_date) {
      const data = inputs;
      data.date = Dateconvert(inputs.date);
      const result = await EditNinetyDayEarning(data, editObject._id);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        dataList();
        onCloseDrawer();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please select a date between the 90 days range.", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // var date = new Date(editObject.date);
    setInputs((prevState) => ({
      ...prevState,
      ["earning"]: editObject.earning,
      ["date"]: editObject.date,
      // ["date"]: "12/12/2022",
      ["description"]: editObject.description,
    }));
  }, [editObject]);
  const maxDateValue = addDays(targetDate);

  console.log(inputs, "inputsinputsinputs");

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 title-golden-large text-center mb-3">
          {/* <p>THE DYNAMITE CHART</p> */}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="col-12 mt-4">
            {/* <TextField
              fullWidth
              sx={{ input: { color: get_root_value("--input-text-color") } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {convertCurrencyToSign(
                      user_profile.nineteen_day_plan_currency
                    )}
                  </InputAdornment>
                ),
              }}
              className="text-area-task inputs-fields"
              id="outlined-required"
              label="Today's Earning"
              type="number"
              required
              placeholder="Add Financial Number Statement"
              name="earning"
              value={inputs.earning}
              onChange={handleChange}
            /> */}
            <NumberFormat
              value={inputs.earning}
              customInput={TextField}
              decimalScale={0}
              sx={{ input: { color: get_root_value("--input-text-color") } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {convertCurrencyToSign(
                      user_profile.nineteen_day_plan_currency
                    )}
                  </InputAdornment>
                ),
              }}
              type="text"
              name="earning"
              className="inputs-fields"
              label="Today's Earning"
              thousandSeparator={true}
              fullWidth
              required
              // onChange={(e) => setValue(parseFloat(e.target.value).toFixed(0))}
              onValueChange={({ value: v }) =>
                setInputs((inputs) => ({
                  ...inputs,
                  ["earning"]: v,
                }))
              }
            />
          </div>
          <div className="col-12 mt-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format="DD/MM/YYYY"
                mask="__/__/____"
                inputFormat="DD/MM/YYYY"
                value={inputs.date}
                fullWidth
                minDate={new Date(targetDate)}
                maxDate={new Date(maxDateValue)}
                onChange={(value) => {
                  setInputs((inputs) => ({
                    ...inputs,
                    ["date"]: value,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="text-area-task inputs-fields"
                    name="date"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
            <TextField
              className="text-area-task inputs-fields"
              fullWidth
              id="outlined-multiline-static"
              label="Add Description"
              multiline
              rows={4}
              name="description"
              value={inputs.description}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 text-end">
            <button
              className="small-contained-button mt-4 mb-4"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEarning;
