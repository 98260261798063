import React from 'react';

function DailyActivities(props) {
  return (
    <div>
      <h1>Daily Activities</h1>
    </div>
  );
}

export default DailyActivities;
