import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Scrollbar from "./Scrollbar";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { disclaimer } from "src/DAL/Disclaimer/Disclaimer";
const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function Disclaimer({
  openPasswordModal,
  setopenPasswordModal,
  disclaimerDetail,
  disclaimerHeading,
  disclaimerButton,
  disclaimerLabel,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setopenPasswordModal(true);
  const handleClose = () => setopenPasswordModal(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await disclaimer();
    if (result.code === 200) {
      setopenPasswordModal(false);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <Modal
        disableTypography="true"
        maxWidth="md"
        sx={{ ml: 25 }}
        open={openPasswordModal}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={handleSubmit}>
          <Box sx={style} className="modal-theme">
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2">
              {disclaimerHeading}
            </Typography>
            <Scrollbar>
              <div className="col-12">
                {disclaimerDetail ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: disclaimerDetail,
                    }}></div>
                ) : (
                  <h2 className="dashboard-heading">{disclaimerDetail}</h2>
                )}
              </div>
            </Scrollbar>
            <div className="col-12 mt-3">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        size="small"
                        checked={checked}
                        onChange={handleCheckBox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={disclaimerLabel}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div className="col-12 text-end">
              <button
                className={`${
                  checked
                    ? "small-contained-button"
                    : "small-contained-button-checked"
                }`}
                disabled={!checked}>
                {disclaimerButton}
              </button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
