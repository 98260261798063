import { invokeApi } from "../../bl_libs/invokeApi";

export const gratitudesListing = async (data) => {
  const requestObj = {
    path: `api/gratitude/gratitude_list_by_member`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddGratitudeApi = async (data) => {
  const requestObj = {
    path: `api/gratitude/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteGratitudeApi = async (gratitude_id) => {
  const requestObj = {
    path: `api/gratitude/${gratitude_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditGratitudeApi = async (gratitude_id, data) => {
  const requestObj = {
    path: `api/gratitude/${gratitude_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
