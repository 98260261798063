import { invokeApi } from "../../bl_libs/invokeApi";
// ====== old API =================
// export const AddEventApi = async (data) => {
//   const requestObj = {
//     path: `api/event/add_member_event/`,
//     method: "POST",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const eventsListing = async (data) => {
//   const requestObj = {
//     path: `api/member/member_event_list`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };
//   return invokeApi(requestObj);
// };

export const DeleteEvent = async (event_slug) => {
  const requestObj = {
    path: `api/event/${event_slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditEventApi = async (event_slug, data) => {
  const requestObj = {
    path: `api/event/update_member_event/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// ==== new API =============
export const add_new_event_api = async (data) => {
  const requestObj = {
    path: `api/event/add/by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_event_api = async (data) => {
  const requestObj = {
    path: `api/event/delete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const new_event_list_api = async (data) => {
  const requestObj = {
    path: `api/event/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_event_iteration_api = async (data, event_slug) => {
  const requestObj = {
    path: `api/event/event_iteration/update/by_member/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
